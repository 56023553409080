<template>
  <v-app id="inspire">
    <v-main style="overflow-y: hidden !important">
      <v-container class="pa-0 fill-height" fluid>
        <v-row no-gutters align="center" justify="center">
          <v-col
            class="d-flex justify-center"
            :class="{
              'align-center': $vuetify.breakpoint.mdAndUp,
              'align-start': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card flat class="pa-0">
              <v-card-title class="justify-left flex-column">
                <div class="text-md-h4 text-h5 primary--text">
                  <v-img src="@/assets/logo_login.png" max-width="300"></v-img>
                </div>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    solo
                    flat
                    background-color="accent"
                    full-width
                    type="text"
                    label="E-mail"
                    v-model="login.email"
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="senha"
                    label="Senha"
                    background-color="accent"
                    prepend-inner-icon="mdi-lock"
                    full-width
                    solo
                    flat
                    v-model="login.senha"
                    class="input-group--focused mt-1"
                    @click:append="show = !show"
                    @keyup.enter="logar"
                  ></v-text-field>

                  <div class="d-flex">
                    <v-checkbox
                      color="secondary"
                      v-model="checkbox"
                      label="Lembrar"
                    >
                    </v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="logar"
                      x-large
                      max-height="50px"
                      color="red"
                      class="mt-1 px-8 white--text"
                      :disabled="!valid"
                      :loading="loading"
                    >
                      Login
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>

              <template v-if="erros.length > 0">
                <v-card-text class="pa-0 red--text">
                  <div class="py-1" v-for="(erro, index) in erros" :key="index">
                    <v-icon class="mr-2 red--text">mdi-alert-circle</v-icon>
                    <span>{{ erro }}</span>
                  </div>
                </v-card-text>
              </template>
            </v-card>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex justify-center align-start"
          >
            <v-img
              class="pa-0"
              src="@/assets/bg_login.png"
              max-width="670"
            ></v-img>
          </v-col>
        </v-row>
        <div
          style="width: 100%"
          class="d-flex flex-column align-center justify-center"
        >
          <v-img contain src="@/assets/text_login.png" max-width="400"></v-img>

          <div class="d-flex justify-center">
            <v-icon large color="red">mdi-facebook</v-icon>
            <v-icon large color="red">mdi-instagram</v-icon>
            <v-icon large color="red">mdi-linkedin</v-icon>
            <v-icon large color="red">mdi-youtube</v-icon>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      show: false,
      valid: false,
      loading: false,
      loadingLanguage: false,
      checkbox: false,
      login: {
        email: "",
        senha: "",
      },
      erros: [],
      rules: {
        required: (value) => !!value || "obrigatório",
        min: (v) => v.length >= 3 || "min 3 caracteres",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "email inválido";
        },
      },
    };
  },

  computed: {
    ...mapGetters("Usuario", [
      "isAdmin",
      "isGestor",
      "isColaborador",
      "isCliente",
    ]),
  },

  methods: {
    async logar() {
      try {
        this.loading = true;
        this.erros = [];
        this.$Progress.start();

        await this.$store.dispatch("Usuario/logarUsuario", this.login);

        const email = this.$ls.get("email");

        if (email && !this.checkbox) {
          this.$ls.remove("email");
        }
        if (this.checkbox) {
          this.$ls.set("email", this.login.email);
        }

        await this.$store.dispatch("Usuario/getUsuario");

        await this.$store.dispatch("Preferencias/getPreferencias");

        if (this.isAdmin) {
          this.$router.push("/admin");
        } else if (this.isGestor) {
          this.$router.push("/gestor");
        } else if (this.isColaborador) {
          this.$router.push("/colaborador");
        } else if (this.isCliente) {
          this.$router.push("/cliente");
        }

        this.loading = false;
        this.$Progress.finish();
      } catch (error) {
        if (error) this.erros.push(error.response.data.message);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },

  mounted() {
    const email = this.$ls.get("email");
    if (email) {
      this.login.email = email;
      this.checkbox = true;
    }

    if (process.env.VUE_APP_SENHA_MICHEL) {
      this.login.senha = process.env.VUE_APP_SENHA_MICHEL;
    }
  },
};
</script>

<style lang="scss" scoped></style>
